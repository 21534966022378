import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comet-chat-conversation-list-page',
  templateUrl: './comet-chat-conversation-list-page.component.html',
  styleUrls: ['./comet-chat-conversation-list-page.component.scss']
})
export class CometChatConversationListPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
